<template>
	<div>
		<h4 class="h4_title">{{translate('history_withdrawal_information')}}</h4>
		<van-cell-group>
			<van-cell :title="translate('history_withdrawal_amount')" :value="total" />
			<van-cell :title="translate('history_handling_fee')" :value="service_fee" v-if="service_fee" />
			<van-cell :title="translate('history_submission_date')" :value="date" />
			<van-cell :title="translate('history_audit_date')" :value="verify_date" v-if="verify_date"/>
			<van-cell :title="translate('history_transfer_date')" :value="transfer_date" v-if="transfer_date"/>
			<van-cell :title="translate('history_current_status')">
				<template #right-icon>
					<span style="color: grey;" v-if="status == 1">{{translate('history_to_be_processed')}}</span>
					<span style="color: steelblue;" v-if="status == 2">{{translate('history_audited')}}</span>
					<span style="color: red;" v-if="status == 3">{{translate('history_cancelled')}}</span>
					<span style="color: green;" v-if="status == 4">{{translate('history_already_transferred')}}</span>
				</template>
			</van-cell>
			<van-cell :title="translate('history_process_result')" :value="reason" v-if="reason"/>
		</van-cell-group>
		
		<h4 class="h4_title">{{translate('history_accounts_receivable')}}</h4>
		<van-cell-group>
			<van-cell :title="translate('history_account_type')" :value="bank_type" />

			<template v-for="(item, key) in bank_info">
				<van-cell :title="key" :value="item" :key="key" v-if="key != '图片'"/>
				<van-cell :title="key" :key="key" is-link v-else @click="imgPreview = true"/>				
			</template>
		</van-cell-group>

		<van-image-preview v-model="imgPreview" :images="[bank_info['图片']]" v-if="bank_info['图片'] != undefined"></van-image-preview>
		
		<div class="common_block" v-if="cancel">
			<van-button type="warning" block round @click="cancelWithdraw">{{translate('history_cancel_apply')}}</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'chujin_info',
		data() {
			return {
				formItem: {
					id: "",
					token: ""
				},
				
				imgPreview: false,

				service_fee: "",
				total: "",
				name: "",
				bank_type: "",
				bank_info: {},
				date: "",
				verify_date: "",
				transfer_date: "",
				status: "",
				reason: "",
				cancel: false,
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.formItem.id = this.$route.query.id
				
				this.loadData()
			}
		},
		methods: {
			loadData() {
				this.$toast.loading({
					message: this.translate('history_loading'),
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/info', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {
						this.service_fee = res.data.service_fee
						this.total = res.data.total
						this.name = res.data.name
						this.bank_type = res.data.bank_type
						this.bank_info = res.data.bank_info
						this.date = res.data.date
						this.verify_date = res.data.verify_date
						this.transfer_date = res.data.transfer_date
						this.status = res.data.status
						this.reason = res.data.reason
						this.cancel = res.data.cancel
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			cancelWithdraw() {
				this.$dialog.confirm({
					message: this.translate('cancel_the_withdrawal_request')
				}).then(() => {
					this.$toast.loading({
						message: this.translate('history_please_wait'),
						forbidClick: true,
					})
					
					this.$axios({
						method: 'post',
						url: 'cash_withdraw/cancel', 
						data: {
							id: this.formItem.id,
							token: this.formItem.token
						}
					}).then ((res) => {
						
						this.$toast.clear()
						
						if (res.success) {
							this.$toast.success({
								message:this.translate('successfully_canceled')
							})

							this.loadData()
						} else {
							this.$dialog.alert({
								message: res.error_text
							})
						}
					})
				})
			}
		}
	}
</script>